import React, { useEffect, useState, useRef } from 'react';
import './index.scss';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { getMediaUrl } from '../../helper/media';
import { useDispatch, useSelector } from 'react-redux';
import socket, { emitUIActionEvent } from '../../helper/socket';
import { ACTION_NAME, WEBSOCKET_CHANNEL } from '../../constants/options';
import { reqSetActiveFloorplan } from '../../reduxs/unit-explore/action';

const FloorPlanGallery = (props) => {
  const { floorPlanGalleryRef } = props;
  const [media, setMedia] = useState([]);
  const refs = useRef({});
  const dispatch = useDispatch();

  const [zoomSize, setZoomSize] = useState({
    size: 60,
  });
  const selectedUnit = useSelector((state) => state.unitExplore.selectedUnit);
  const authUser = useSelector((state) => state.user.data);
  const isPresentation = useSelector((state) => state.home.isPresentation);
  const activeFloorplan = useSelector((state) => state.unitExplore.activeFloorplan);

  useEffect(() => {
    if (isPresentation) {
      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, ({ content }) => {
        if (content.action === ACTION_NAME.ZOOM_IN_FLOORPLAN) {
          return zoomIn();
        }

        if (content.action === ACTION_NAME.ZOOM_OUT_FLOORPLAN) {
          return zoomOut();
        }
      });
    }
  }, []);

  const settings = {
    dots: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    className: 'slides',
    focusOnSelect: true,
    adaptiveHeight: false,
    arrows: false,
    fade: true,
    accessibility: true,
    afterChange: (index) => {
      zoomSize.size = 60;
      if (refs.current[activeFloorplan.index] != undefined) {
        refs.current[activeFloorplan.index].style.backgroundSize = `60%`;
      }
      if (refs.current[index] != undefined) {
        refs.current[index].style.backgroundSize = `60%`;
      }
      if (!isPresentation) {
        emitUIActionEvent(authUser, ACTION_NAME.CLICK_FLOORPLAN_SLIDE_BTN, { index });
      }
      dispatch(reqSetActiveFloorplan(index));
    },
  };

  useEffect(async () => {
    if (selectedUnit) {
      const floorPlan = selectedUnit?.floor_plan;
      setMedia(floorPlan?.media ? floorPlan.media : []);
    }
  }, [selectedUnit]);

  const zoomIn = () => {
    if (!isPresentation) {
      emitUIActionEvent(authUser, ACTION_NAME.ZOOM_IN_FLOORPLAN);
    }
    if (refs.current[activeFloorplan.index] != undefined) {
      refs.current[activeFloorplan.index].style.backgroundSize = `${zoomSize.size + 10}%`;
    }
    zoomSize.size = zoomSize.size + 10;
  };

  const zoomOut = () => {
    if (!isPresentation) {
      emitUIActionEvent(authUser, ACTION_NAME.ZOOM_OUT_FLOORPLAN);
    }
    if (zoomSize.size > 50) {
      if (refs.current[activeFloorplan.index] != undefined) {
        refs.current[activeFloorplan.index].style.backgroundSize = `${zoomSize.size - 10}%`;
      }
      zoomSize.size = zoomSize.size - 10;
    }
  };

  return (
    <>
      <div className="wrap-floorplan-gallery">
        <Slider {...settings} ref={floorPlanGalleryRef} className="slider-floorplan">
          {media.map((item, key) => {
            return (
              <div key={key}>
                <div
                  ref={(ref) => (refs.current[key] = ref)}
                  style={{ backgroundImage: `url(${getMediaUrl(item.path)})` }}
                  className="floorplan-item"
                />
              </div>
            );
          })}
        </Slider>
      </div>
      <div className={`wrap-zoom-btn ${isPresentation ? 'invisible' : ''}`}>
        <div className="zoom-btn">
          <img onClick={() => zoomOut()} className="me-4" src="/icons/minus.svg" alt="" />
          <img onClick={() => zoomIn()} src="/icons/plus.svg" alt="" />
        </div>
      </div>
    </>
  );
};

export default FloorPlanGallery;
