import React, { useEffect, useState } from 'react';
import Amenities from '../../components/amenities';
import { useDispatch, useSelector } from 'react-redux';
import TopNav from '../../components/top-nav';
import UnitExplore from '../../components/unit-explore';
import BottomNavigation from '../../components/bottom-navigation';
import PanoViewer from '../../components/pano-viewer';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import RsBtn from '../../components/rs-btn';
import RelatedAsset from '../../components/related-asset';
import Gallery from '../../components/gallery';
import Team from '../../components/team';
import Location from '../../components/location';
//import lifxApi from '../../apis/api/lifx';
import Loading from '../../components/loading';
import ExcutiveSummary from '../../components/excutive-summary';
import Search from '../../components/search';
import _3dSettings from '../../apis/api/_3dSettings';
import Modal from '../../components/modal';
import amenityApi from '../../apis/api/amenities';
import * as unitExploreAct from '../../reduxs/unit-explore/action';

import { reqSetExploreModal, reqSetIsShowExploreModal } from '../../reduxs/explore-modal/action';
import { reqSetActiveAmenity, reqSetActiveAmenityArea } from '../../reduxs/amenities/action';
import { reqSetIsShowFilter, reqSetSelectedUnit, reqGetUnitList, reqIsShowGallery } from '../../reduxs/unit-explore/action';
import {
  reqSetIsExpandNav,
  reqSetIsShowBottomNav,
  reqSetIsShowImmerse,
  reqSetIsTransparent,
  reqSetPage,
  reqSetActivePatourId,
  reqSetActiveGalleryId,
  reqSetIsPresentation
} from '../../reduxs/home/action';
import { PAGES, LAYERS, ACTION_NAME, WEBSOCKET_CHANNEL } from '../../constants/options';
import { reqGetUserProfile } from '../../reduxs/user/action';
import { useHistory, useLocation } from 'react-router';
import socket, { emitUIActionEvent } from '../../helper/socket';
import { Vector3, Color } from 'three';
import StartSessionModal from '../../components/sessions/start-session-modal';
import CustomerDetailModal from '../../components/sessions/customer-detail-modal';
import CreateCustomerModal from '../../components/sessions/create-customer-modal';

const ReactUIPresentation = (props) => {
  const { isLoading, isIntroduction, setIsIntroduction, roles, authMiddleware, controls, refScene } = props;
  const dispatch = useDispatch();
  const history = useHistory();

  const page = useSelector((state) => state.home.page);
  const unitQuery = useSelector((state) => state.unitExplore.unitQuery);
  const isShowBottomNav = useSelector((state) => state.home.isShowBottomNav);
  const isShowImmerse = useSelector((state) => state.home.isShowImmerse);
  const isNavExpand = useSelector((state) => state.home.isNavExpand);
  const isShowExploreModal = useSelector((state) => state.exploreModal.isShowExploreModal);
  const isShowStartSessionModal = useSelector((state) => state.home.isShowStartSessionModal);
  const isShowCustomerDetailModal = useSelector((state) => state.home.isShowCustomerDetailModal);
  const isShowCreateCustomerModal = useSelector((state) => state.home.isShowCreateCustomerModal);
  const selectedUnit = useSelector((state) => state.unitExplore.selectedUnit);

  const [showRs, setShowRs] = useState(false);
  const authUser = useSelector((state) => state.user.data);

  let position1 = new Vector3();
  let position2 = new Vector3();
  let color = new Color('#ff00ff');

  useEffect(() => {
    dispatch(reqSetIsPresentation(true));
    dispatch(reqGetUserProfile());
    authMiddleware(roles, history);
  }, []);

  useEffect(() => {
    if (authUser) {
      socket.auth = {
        userId: authUser.id
      }
      socket.connect();

      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, ({content}) => {
        handleApplyAction(content.action, content.data);
      });

      socket.on(WEBSOCKET_CHANNEL.SHARE_CAMERA_ACTION, ({ content }) => {
        if (controls.current != null) {
          position1.x = content.position.x;
          position1.y = content.position.y;
          position1.z = content.position.z;

          controls.current.object.position.copy(position1);
          controls.current.object.quaternion.set(content.quaternion.x, content.quaternion.y, content.quaternion.z, content.quaternion.w);

          controls.current.object.zoom = content.zoom;
          controls.current.object.updateProjectionMatrix();
       }
      });

      return () => {
        console.log('Socket disconnect');
        socket.disconnect();
      }
    }
  }, [authUser]);

  useEffect(() => {
    if (page == PAGES.UNIT_EXPLORER_PAGE) dispatch(reqGetUnitList(unitQuery));
  }, [unitQuery]);

  useEffect(() => {
    if(selectedUnit) {
      setActiveObjectIds([selectedUnit['3d_asset'].id]);
    }
  }, [selectedUnit]);

  useEffect(async () => {
    if (controls.current == null) {
      return;
    }

    controls.current.showAll();
    controls.current.enableAll();
    controls.current.hideLayerOnly(LAYERS.INVISIBLE_BUT_ENABLE);
    controls.current.hideLayer(LAYERS.DISABLE);
    controls.current.disableLayer(LAYERS.VISIBLE_BUT_DISABLE);

    if (page == PAGES.NONE) {
      controls.current.disableLayer(LAYERS.UNITS);
    } else if (page == PAGES.UNIT_EXPLORER_PAGE) {
      controls.current.hideLayer(LAYERS.HOTPOT);
    }
  }, [page]);

  const handleApplyAction = (action, data) => {
    if (action == ACTION_NAME.CLICK_UNIT_EXPLORE_MENU) {
      return handleClickExplore();
    }

    if (action == ACTION_NAME.CLICK_CUBE_MENU) {
      setIsIntroduction(true);

      return handleClickCube();
    }

    if (action == ACTION_NAME.CLICK_GALLERY_MENU) {
      return handleClickGallery();
    }

    if (action == ACTION_NAME.CLICK_LOCATION_MENU) {
      return handleClickLocation();
    }

    if (action == ACTION_NAME.CLICK_TEAM_MENU) {
      return handleClickTeam();
    }

    if (action == ACTION_NAME.CLICK_IMMERSER_MENU) {
      return handleClickImmerse();
    }

    if (action === ACTION_NAME.CLICK_UNIT) {
      return handleUnitClick(data?.unit);
    }

    if (action === ACTION_NAME.CLICK_SEARCH_MENU) {
      return handleClickSearch();
    }

    if (action === ACTION_NAME.CLOSE_PANO_VIEWER) {
      return handleClosePanoViewer();
    }

    if (action === ACTION_NAME.EXPLORE_SHOW_FILTER) {
      let showFilter = data?.showFilter ?? false;
      dispatch(reqSetIsShowFilter(showFilter));
      return;
    }
  }

  // Color:
  function getModelFileName(model) {
    let name = model['3d_filename'];
    // remove file extension
    name = name.split('.').slice(0, -1).join('.');
    // join any words with '_'
    name = name.split(' ').join('_');
    return name.toLowerCase();
  }

  const setSelectInstance = (instance, status) => {
    if (controls.current == null) {
      return;
    }
    if (refScene.current == null) {
      return;
    }
    let cameraUserData = controls.current.object.userData;
    let meshInstanceMap = cameraUserData.meshInstanceMap;

    let name = getModelFileName(instance);
    let meshInstance = meshInstanceMap[name];
    if (meshInstance == null) {
      return null;
    }
    let index = meshInstance.instances.findIndex((e) => e.id === instance.id);
    if (index < 0) {
      return null;
    }
    let imesh = refScene.current.getObjectByName(name);
    if (imesh == null) {
      return null;
    }
    let userData = imesh.userData[instance.id];
    if (userData == null) {
      return null;
    }

    if (status == 0) {
      color.set(userData.color);
    } else if (status == 1) {
      color.set(userData.hover_color);
    } else if (status == 2) {
      color.set(userData.active_color);
    }

    imesh.userData[instance.id].isActive = status == 2;

    imesh.setColorAt(index, color);
    if (imesh.instanceColor != null) {
      imesh.instanceColor.needsUpdate = true;
    }
    return userData.light;
  };

  function setActiveObjectIds(ids) {
    if (controls.current == null) {
      return;
    }
    let userData = controls.current.object.userData;
    let prevIds = userData.selectedObjectIds.slice();
    handleResetObjectColor(prevIds);
    controls.current.object.userData.selectedObjectIds = ids;

    const lights = handleSetActiveObjectColor(ids);
  }

  const handleResetObjectColor = (prevIds) => {
    if (controls.current == null) {
      return;
    }
    let userData = controls.current.object.userData;
    prevIds.forEach((id) => {
      let instance = userData.mapObjectId[id];
      if (instance != null) {
        setSelectInstance(instance, 0);
      }
    });
  };

  const handleSetActiveObjectColor = (ids) => {
    const lights = [];

    if (refScene.current == null || controls.current == null) {
      return lights;
    }
    let userData = controls.current.object.userData;

    ids.forEach((id) => {
      let instance = userData.mapObjectId[id];
      if (instance != null) {
        let light = setSelectInstance(instance, 2);
        light && lights.push(light);
      }
    });

    return lights;
  };

  // End: Color

  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  const handleAmenityItemClick = async (amenityId) => {
    const res = await amenityApi.getAmenityDetail(amenityId);
    const amenity = res?.data;

    if (amenity) {
      dispatch(reqSetActiveAmenityArea(amenity.area));
      dispatch(reqSetActiveAmenity(amenityId));

      amenity?.modal && dispatch(reqSetIsShowExploreModal(true));
      dispatch(reqSetExploreModal(amenity?.modal));
    }
  };

  const handleUnitClick = (unit) => {
    emitUIActionEvent(authUser, ACTION_NAME.CLICK_UNIT, {
      unit: unit,
    });

    if (!controls.current) return;

    if (!unit['3d_asset']) {
      setActiveObjectIds([]);

      return;
    }

    const object = unit['3d_asset'];
    dispatch(reqSetActiveGalleryId(''));
    dispatch(reqIsShowGallery(false));
    setActiveObjectIds([object.id]);
    dispatch(reqSetSelectedUnit(unit.id));
    dispatch(reqSetIsShowFilter(false));
  };

  const handleAreaClick = (area) => {
    if (!controls.current) return;

    if (!area['3d_asset']) {
      setActiveObjectIds([]);
      return;
    }
  };

  const handleClickImmerse = () => {
    resetState();

    dispatch(reqSetIsShowBottomNav(false));
    dispatch(reqSetActivePatourId('60b8a20d6be0a5d2645b1871'));
    dispatch(reqSetPage(PAGES.IMMERSE_PAGE));
    dispatch(reqSetIsShowImmerse(true));
  };

  const handleClickCube = () => {
    resetState();
    dispatch(reqSetIsExpandNav(!isNavExpand));
  };

  const handleClickExplore = () => {
    resetState();
    dispatch(reqSetPage(PAGES.UNIT_EXPLORER_PAGE));
  };

  const handleClickGallery = () => {
    resetState();
    dispatch(reqSetActiveGalleryId('60cb329ee76f1f0016defcff'))
    dispatch(reqSetPage(PAGES.GALLERY_PAGE));
  };

  const handleClickLocation = () => {
    resetState();
    dispatch(reqSetPage(PAGES.LOCATION_PAGE));
  };

  const handleClickTeam = () => {
    resetState();
    dispatch(reqSetPage(PAGES.TEAM_PAGE));
  };

  const handleClickSearch = () => {
    resetState();
    dispatch(reqSetPage(PAGES.SEARCH_PAGE));
  };

  const resetState = () => {
    if (controls.current) {
      controls.current.needReloadSelectedHotspotId = true;
      controls.current.selectedHotspotId = '';
    }
    dispatch(reqSetExploreModal(''));
    dispatch(reqSetIsShowExploreModal(false));
    dispatch(reqSetActiveAmenityArea(''));
    dispatch(reqSetActiveAmenity(''));
    dispatch(reqSetPage(PAGES.NONE));
    dispatch(reqSetIsShowBottomNav(true));
    setActiveObjectIds([]);
    dispatch(reqSetSelectedUnit(''));
    dispatch(reqSetIsShowImmerse(false));
    dispatch(reqSetIsTransparent(false));
    dispatch(reqSetActiveGalleryId(''));
    dispatch(reqIsShowGallery(false));
    dispatch(reqSetSelectedUnit());
    onResetAllFilter();
  };

  const onResetAllFilter = () => {
    dispatch(unitExploreAct.reqFilterUnitArea());
    dispatch(unitExploreAct.reqFilterUnitEnclave([]));
    dispatch(unitExploreAct.reqFilterUnitFloorplanCode());
    dispatch(unitExploreAct.reqFilterUnitFloorplanName());
    dispatch(unitExploreAct.reqFilterUnitParkingStall([]));
    dispatch(unitExploreAct.reqFilterUnitSquareFootage());
    dispatch(unitExploreAct.reqFilterUnitStatus());
    dispatch(unitExploreAct.reqFilterUnitSize());
    dispatch(unitExploreAct.reqFilterUnitPrice());
  }

  const handleClosePanoViewer = () => {
    dispatch(reqSetIsShowImmerse(false));
    if (controls.current) {
      controls.current.needReloadSelectedHotspotId = true;
      controls.current.selectedHotspotId = '';
    }
    dispatch(reqSetIsShowBottomNav(true));
    dispatch(reqSetIsShowExploreModal(true));
    dispatch(reqSetPage(PAGES.NONE));
  };

  useEffect(() => {
      socket.on('share_screen_ui_action', ({ content }) => {
        if (content.action === ACTION_NAME.CLICK_END_SESSION) {
          dispatch(reqSetPage(PAGES.ONBOARD_PAGE));
        }
      });
  }, []);

  return (
    <div className="wrap-index-page">
      <TopNav
        isShow={page !== PAGES.ONBOARD_PAGE}
        isLoading={props.isLoading}
        handleClickCube={handleClickCube}
        handleClickImmerse={handleClickImmerse}
        handleClickExplore={handleClickExplore}
        handleClickGallery={handleClickGallery}
        handleClickLocation={handleClickLocation}
        handleClickTeam={handleClickTeam}
        handleClickSearch={handleClickSearch}
      />

      <TransitionGroup>
        {page === PAGES.AMENITY_PAGE && (
          <CSSTransition timeout={1000} classNames="fade-item">
            <Amenities />
          </CSSTransition>
        )}
      </TransitionGroup>
      <CSSTransition
        in={page === PAGES.UNIT_EXPLORER_PAGE}
        timeout={1000}
        classNames="fade-left"
        unmountOnExit
      >
        <UnitExplore
          handleUnitClick={(a) => handleUnitClick(a)}
          setActiveObjectIds={setActiveObjectIds}
          handleAreaClick={(area) => handleAreaClick(area)}
        />
      </CSSTransition>

      <TransitionGroup>
        {page !== PAGES.ONBOARD_PAGE && isShowBottomNav && (
          <CSSTransition timeout={1000} classNames="fade-item">
            <BottomNavigation />
          </CSSTransition>
        )}
      </TransitionGroup>

      <TransitionGroup>
        {isShowImmerse && (
          <CSSTransition timeout={0} classNames="fade-item">
            <PanoViewer handleClosePanoViewer={handleClosePanoViewer} />
          </CSSTransition>
        )}
      </TransitionGroup>

      {false && page !== PAGES.ONBOARD_PAGE && <RsBtn showRs={showRs} setShowRs={setShowRs} />}
      <TransitionGroup>
        {page == PAGES.GALLERY_PAGE && (
          <CSSTransition timeout={1000} classNames="fade-item">
            <Gallery />
          </CSSTransition>
        )}
      </TransitionGroup>
      <TransitionGroup>
        {page == PAGES.TEAM_PAGE && (
          <CSSTransition timeout={1000} classNames="fade-item">
            <Team />
          </CSSTransition>
        )}
      </TransitionGroup>
      <TransitionGroup>
        {page == PAGES.LOCATION_PAGE && (
          <CSSTransition timeout={1000} classNames="fade-item">
            <Location />
          </CSSTransition>
        )}
      </TransitionGroup>
      <TransitionGroup>
        {page == PAGES.EXCUTIVE_SUMMARY_PAGE && (
          <CSSTransition timeout={1000} classNames="fade-item">
            <ExcutiveSummary />
          </CSSTransition>
        )}
      </TransitionGroup>
      <TransitionGroup>
        {page == PAGES.SEARCH_PAGE && (
          <CSSTransition timeout={1000} classNames="fade-item">
            <Search
              handleUnitClick={(a) => handleUnitClick(a)}
              handleAmenityItemClick={(id) => handleAmenityItemClick(id)}
            />
          </CSSTransition>
        )}
      </TransitionGroup>
      {page == PAGES.ONBOARD_PAGE && (
        <Loading
          isLoading={props.isLoading}
          setIsIntroduction={setIsIntroduction}
        />
      )}
      <TransitionGroup>
        {isShowExploreModal && (
          <CSSTransition timeout={250} classNames="fade-item">
            <Modal/>
          </CSSTransition>
        )}
      </TransitionGroup>
      {
        isShowStartSessionModal && <StartSessionModal />
      }
      {
        isShowCustomerDetailModal && <CustomerDetailModal />
      }
      {
        isShowCreateCustomerModal && <CreateCustomerModal />
      }
    </div>
  );
};

export default ReactUIPresentation;
