import React, { useEffect, useRef, useState } from 'react';
import CanvasBox from '../../components/3d-scene/CanvasBox';
import Amenities from '../../components/amenities';
import { useDispatch, useSelector } from 'react-redux';
import TopNav from '../../components/top-nav';
import UnitExplore from '../../components/unit-explore';
import BottomNavigation from '../../components/bottom-navigation';
import PanoViewer from '../../components/pano-viewer';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import RsBtn from '../../components/rs-btn';
import RelatedAsset from '../../components/related-asset';
import Gallery from '../../components/gallery';
import HomeGallery from '../../components/home-gallery';
import Team from '../../components/team';
import Location from '../../components/location';
import lifxApi from '../../apis/api/lifx';
import Loading from '../../components/loading';
import ExcutiveSummary from '../../components/excutive-summary';
import Search from '../../components/search';
import _3dSettings from '../../apis/api/_3dSettings';
import Modal from '../../components/modal';
import amenityApi from '../../apis/api/amenities';
import * as unitExploreAct from '../../reduxs/unit-explore/action';

import { reqSetExploreModal, reqSetIsShowExploreModal } from '../../reduxs/explore-modal/action';
import { reqSetActiveAmenity, reqSetActiveAmenityArea } from '../../reduxs/amenities/action';
import { reqSetIsShowFilter, reqSetSelectedUnit, reqGetUnitList, reqIsShowGallery } from '../../reduxs/unit-explore/action';
import {
  reqSetIsExpandNav,
  reqSetIsShowBottomNav,
  reqSetIsShowImmerse,
  reqSetIsTransparent,
  reqSetPage,
  reqSetActivePatourId,
  reqSetActiveGalleryId,
  reqSetIsPresentation,
  reqSetPanoName
} from '../../reduxs/home/action';
import { PAGES, LAYERS, ACTION_NAME } from '../../constants/options';
import { threePosition, setColor } from '../../helper/threeHeper';
import { reqGetUserProfile } from '../../reduxs/user/action';
import { useHistory, useLocation } from 'react-router';
import socket, { emitUIActionEvent } from '../../helper/socket';
import { Color } from 'three';
import PostEngagement from '../../components/post-engagement';
import { LIFX_ADMIN } from '../../config';
import { isArray } from 'lodash';

const ReactUI = (props) => {
  const { isLoading, setIsIntroduction, roles, authMiddleware, controls, refScene } = props;
  const dispatch = useDispatch();
  const history = useHistory();

  const customerId = useQuery().get('customer');
  const page = useSelector((state) => state.home.page);
  const unitQuery = useSelector((state) => state.unitExplore.unitQuery);
  const isShowBottomNav = useSelector((state) => state.home.isShowBottomNav);
  const isShowImmerse = useSelector((state) => state.home.isShowImmerse);
  const isNavExpand = useSelector((state) => state.home.isNavExpand);
  const isShowExploreModal = useSelector((state) => state.exploreModal.isShowExploreModal);
  const settings = useSelector((state) => state.scene.settings);
  const selectedUnit = useSelector((state) => state.unitExplore.selectedUnit);

  const [isShowRelatedAsset, setIsShowRelatedAsset] = useState(false);
  const authUser = useSelector((state) => state.user.data);
  let color = new Color('#ff00ff');

  useEffect(() => {
    dispatch(reqSetIsPresentation(false));
    dispatch(reqGetUserProfile());
    authMiddleware(roles, history);
    if (!customerId) {
      history.push('/holding');
    }
  }, []);

  useEffect(() => {
    if(authUser) {
      socket.auth = {
        userId: authUser.id
      };
      socket.connect();

      return () => {
        socket.disconnect();
      }
    }
  }, [authUser]);

  useEffect(() => {
    if (page == PAGES.UNIT_EXPLORER_PAGE) dispatch(reqGetUnitList(unitQuery));
  }, [unitQuery]);


  useEffect(() => {
    if(selectedUnit) {
      setActiveObjectIds([selectedUnit['3d_asset'].id]);
    }
  }, [selectedUnit]);

  useEffect(async () => {
    if (controls.current == null) {
      return;
    }
    controls.current.showAll();
    controls.current.enableAll();
    controls.current.hideLayerOnly(LAYERS.INVISIBLE_BUT_ENABLE);
    controls.current.hideLayer(LAYERS.DISABLE);
    controls.current.disableLayer(LAYERS.VISIBLE_BUT_DISABLE);
    
    if (page == PAGES.NONE) {
      controls.current.disableLayer(LAYERS.UNITS);
    } else if (page == PAGES.UNIT_EXPLORER_PAGE) {
      controls.current.hideLayer(LAYERS.HOTPOT);
    }
  }, [page]);

  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  // Color:
  function getModelFileName(model) {
    let name = model['3d_filename'];
    // remove file extension
    name = name.split('.').slice(0, -1).join('.');
    // join any words with '_'
    name = name.split(' ').join('_');
    return name.toLowerCase();
  }

  const setSelectInstance = (instance, status) => {
    if (controls.current == null) {
      return;
    }
    if (refScene.current == null) {
      return;
    }
    let cameraUserData = controls.current.object.userData;
    let meshInstanceMap = cameraUserData.meshInstanceMap;

    let name = getModelFileName(instance);
    let meshInstance = meshInstanceMap[name];
    if (meshInstance == null) {
      return null;
    }
    let index = meshInstance.instances.findIndex((e) => e.id === instance.id);
    if (index < 0) {
      return null;
    }
    let imesh = refScene.current.getObjectByName(name);
    if (imesh == null) {
      return null;
    }
    let userData = imesh.userData[instance.id];
    if (userData == null) {
      return null;
    }

    if (status == 0) {
      color.set(userData.color);
    } else if (status == 1) {
      color.set(userData.hover_color);
    } else if (status == 2) {
      color.set(userData.active_color);
    }

    imesh.userData[instance.id].isActive = status == 2;

    imesh.setColorAt(index, color);
    if (imesh.instanceColor != null) {
      imesh.instanceColor.needsUpdate = true;
    }
    return userData.light;
  };

  function setActiveObjectIds(ids) {
    if (controls.current == null) {
      return;
    }
    let userData = controls.current.object.userData;
    let prevIds = userData.selectedObjectIds.slice();
    handleResetObjectColor(prevIds);
    controls.current.object.userData.selectedObjectIds = ids;
    const lights = handleSetActiveObjectColor(ids);

    if (LIFX_ADMIN.includes(authUser.id)) {
      lifxApi.turnOffAll().then(async (res) => {
        console.log('turn off lights');
        await new Promise(r => setTimeout(r, 3000));
        handleUpdateLightState(lights);
      });
    }
  }

  const handleResetObjectColor = (prevIds) => {
    if (controls.current == null) {
      return;
    }
    let userData = controls.current.object.userData;
    prevIds.forEach((id) => {
      let instance = userData.mapObjectId[id];
      if (instance != null) {
        setSelectInstance(instance, 0);
      }
    });
  };

  const handleSetActiveObjectColor = (ids) => {
    const lights = [];

    if (refScene.current == null || controls.current == null) {
      return lights;
    }
    let userData = controls.current.object.userData;

    ids.forEach((id) => {
      let instance = userData.mapObjectId[id];
      if (instance != null) {
        let light = setSelectInstance(instance, 2);
        light && lights.push(light);
      }
    });

    return lights;
  };

  const handleUpdateLightState = (lights) => {
    const lightGroups = lights.reduce((prev, value) => {
      const { uuid, zone } = value;

      if (!isArray(zone)) return prev;

      if (prev[uuid]) {
        prev[uuid].push(...zone);
        prev[uuid] = [...new Set(prev[uuid])];
      } else {
        prev[uuid] = [...zone];
      }

      return prev;
    }, {});

    Object.keys(lightGroups).length && lifxApi.setStates(lightGroups).then(res => console.log(res, 'turn on lights'));
  };
  // End: Color

  const handleAmenityItemClick = async (amenityId) => {
    const res = await amenityApi.getAmenityDetail(amenityId);
    const amenity = res?.data;

    if (amenity) {
      dispatch(reqSetActiveAmenityArea(amenity.area));
      dispatch(reqSetActiveAmenity(amenityId));

      amenity?.modal && dispatch(reqSetIsShowExploreModal(true));
      dispatch(reqSetExploreModal(amenity?.modal));
    }
  };

  const handleUnitClick = (unit) => {
    emitUIActionEvent(authUser, ACTION_NAME.CLICK_UNIT, {
      unit: unit,
    });

    if (!controls.current) return;

    if (!unit['3d_asset']) {
      // setActiveObjectIds([]);

      return;
    }

    const object = unit['3d_asset'];
    dispatch(reqSetActiveGalleryId(''));
    dispatch(reqIsShowGallery(false));
    // setActiveObjectIds([object.id]);
    dispatch(reqSetSelectedUnit(unit.id));
    dispatch(reqSetIsShowFilter(false));
    handleMoveCamera(object);
  };

  const handleAreaClick = (area) => {
    if (!controls.current) return;

    if (!area['3d_asset']) {
      setActiveObjectIds([]);
      return;
    }

    handleMoveCamera(area['3d_asset']);
  };

  const handleMoveCamera = (object, onCompleted = () => {}) => {
    if (!object.xyz_position) return;

    const position = threePosition(object.xyz_position);

    if (object.cam_position != null) {
      const camPosition = threePosition(object.cam_position);
      const camLookAtPosition =
        object.cam_focus_point_position != null
          ? threePosition(object.cam_focus_point_position)
          : position;
      controls.current.lookAtAndMovePosition(camLookAtPosition, camPosition, onCompleted);
    }
  };

  const handleClickImmerse = () => {
    emitUIActionEvent(authUser, ACTION_NAME.CLICK_IMMERSER_MENU);
    resetState();

    dispatch(reqSetIsShowBottomNav(true));
    dispatch(reqSetActivePatourId('60b8a20d6be0a5d2645b1871'));
    dispatch(reqSetPage(PAGES.IMMERSE_PAGE));
    dispatch(reqSetIsShowImmerse(true));
  };

  const handleClickCube = () => {
    if (isLoading) return;

    resetState();
    dispatch(reqSetIsExpandNav(!isNavExpand))
  };

  const handleClickExplore = () => {
    emitUIActionEvent(authUser, ACTION_NAME.CLICK_UNIT_EXPLORE_MENU);
    resetState();
    dispatch(reqSetPage(PAGES.UNIT_EXPLORER_PAGE));
  };

  const handleClickGallery = () => {
    emitUIActionEvent(authUser, ACTION_NAME.CLICK_GALLERY_MENU);
    resetState();
    dispatch(reqSetActiveGalleryId('60cb329ee76f1f0016defcff'));
    dispatch(reqSetPage(PAGES.GALLERY_PAGE));
  };

  const handleClickLocation = () => {
    emitUIActionEvent(authUser, ACTION_NAME.CLICK_LOCATION_MENU);
    resetState();
    dispatch(reqSetPage(PAGES.LOCATION_PAGE));
  };

  const handleClickTeam = () => {
    emitUIActionEvent(authUser, ACTION_NAME.CLICK_TEAM_MENU);
    resetState();
    dispatch(reqSetPage(PAGES.TEAM_PAGE));
  };

  const handleClickSearch = () => {
    if (page == PAGES.ANIMATE_PAGE) {
      return;
    }
    emitUIActionEvent(authUser, ACTION_NAME.CLICK_SEARCH_MENU);
    resetState();
    dispatch(reqSetPage(PAGES.SEARCH_PAGE));
  };

  const resetState = () => {
    if (controls.current) {
      controls.current.needReloadSelectedHotspotId = true;
      controls.current.selectedHotspotId = '';
    }
    dispatch(reqSetExploreModal(''));
    dispatch(reqSetIsShowExploreModal(false));
    dispatch(reqSetActiveAmenityArea(''));
    dispatch(reqSetActiveAmenity(''));
    dispatch(reqSetPage(PAGES.NONE));
    dispatch(reqSetIsShowBottomNav(true));
    setActiveObjectIds([]);
    dispatch(reqSetSelectedUnit(''));
    setIsShowRelatedAsset(false);
    dispatch(reqSetIsShowImmerse(false));
    dispatch(reqSetIsTransparent(false));
    dispatch(reqSetActiveGalleryId(''));
    dispatch(reqIsShowGallery(false));
    dispatch(reqSetSelectedUnit());
    dispatch(reqSetPanoName(''));
    onResetAllFilter();

    handleMoveCamera({
      xyz_position: settings.cam_focus_position,
      cam_position: settings.cam_position,
      cam_focus_point_position: settings.cam_focus_position,
    });
  };

  const onResetAllFilter = () => {
    dispatch(unitExploreAct.reqFilterUnitArea());
    dispatch(unitExploreAct.reqFilterUnitEnclave([]));
    dispatch(unitExploreAct.reqFilterUnitFloorplanCode());
    dispatch(unitExploreAct.reqFilterUnitFloorplanName());
    dispatch(unitExploreAct.reqFilterUnitParkingStall([]));
    dispatch(unitExploreAct.reqFilterUnitSquareFootage());
    dispatch(unitExploreAct.reqFilterUnitStatus());
    dispatch(unitExploreAct.reqFilterUnitSize());
    dispatch(unitExploreAct.reqFilterUnitPrice());
  }

  const handleClosePanoViewer = () => {
    emitUIActionEvent(authUser, ACTION_NAME.CLOSE_PANO_VIEWER);
    dispatch(reqSetIsShowImmerse(false));
    if (controls.current) {
      controls.current.needReloadSelectedHotspotId = true;
      controls.current.selectedHotspotId = '';
    }
    dispatch(reqSetIsShowBottomNav(true));
    dispatch(reqSetIsShowExploreModal(true));
    dispatch(reqSetPage(PAGES.NONE));
    dispatch(reqSetPanoName(''));
  };

  const handleCloseModal = () => {
    if (controls.current) {
      controls.current.needReloadSelectedHotspotId = true;
      controls.current.selectedHotspotId = '';
    }
  };

  return (
    <div className="wrap-index-page">
      <TopNav
        isShow={page !== PAGES.ONBOARD_PAGE}
        isLoading={isLoading}
        handleClickCube={handleClickCube}
        handleClickImmerse={handleClickImmerse}
        handleClickExplore={handleClickExplore}
        handleClickGallery={handleClickGallery}
        handleClickLocation={handleClickLocation}
        handleClickTeam={handleClickTeam}
        handleClickSearch={handleClickSearch}
      />

      <TransitionGroup>
        {page === PAGES.AMENITY_PAGE && (
          <CSSTransition timeout={1000} classNames="fade-item">
            <Amenities />
          </CSSTransition>
        )}
      </TransitionGroup>
      <CSSTransition
        in={page === PAGES.UNIT_EXPLORER_PAGE}
        timeout={1000}
        classNames="fade-left"
        unmountOnExit
      >
        <UnitExplore
          handleUnitClick={(a) => handleUnitClick(a)}
          setActiveObjectIds={setActiveObjectIds}
          handleAreaClick={(area) => handleAreaClick(area)}
        />
      </CSSTransition>

      <TransitionGroup>
        {page !== PAGES.ONBOARD_PAGE && isShowBottomNav && page !== PAGES.END_SESSION_PAGE && (
          <CSSTransition timeout={1000} classNames="fade-item">
            <BottomNavigation resetState={resetState} />
          </CSSTransition>
        )}
      </TransitionGroup>

      <TransitionGroup>
        {isShowImmerse && (
          <CSSTransition timeout={0} classNames="fade-item">
            <PanoViewer handleClosePanoViewer={handleClosePanoViewer} />
          </CSSTransition>
        )}
      </TransitionGroup>

      <TransitionGroup>
        {isShowRelatedAsset && (
          <CSSTransition timeout={1000} classNames="fade-item">
            <RelatedAsset />
          </CSSTransition>
        )}
      </TransitionGroup>
      <TransitionGroup>
        {page == PAGES.GALLERY_PAGE && (
          <CSSTransition timeout={1000} classNames="fade-item">
            <HomeGallery />
          </CSSTransition>
        )}
      </TransitionGroup>
      <TransitionGroup>
        {page == PAGES.TEAM_PAGE && (
          <CSSTransition timeout={1000} classNames="fade-item">
            <Team />
          </CSSTransition>
        )}
      </TransitionGroup>
      <TransitionGroup>
        {page == PAGES.LOCATION_PAGE && (
          <CSSTransition timeout={1000} classNames="fade-item">
            <Location />
          </CSSTransition>
        )}
      </TransitionGroup>
      <TransitionGroup>
        {page == PAGES.EXCUTIVE_SUMMARY_PAGE && (
          <CSSTransition timeout={1000} classNames="fade-item">
            <ExcutiveSummary />
          </CSSTransition>
        )}
      </TransitionGroup>
      <TransitionGroup>
        {page == PAGES.SEARCH_PAGE && (
          <CSSTransition timeout={1000} classNames="fade-item">
            <Search
              handleUnitClick={(a) => handleUnitClick(a)}
              handleAmenityItemClick={(id) => handleAmenityItemClick(id)}
            />
          </CSSTransition>
        )}
      </TransitionGroup>
      {page == PAGES.ONBOARD_PAGE && (
        <Loading
          isLoading={isLoading}
          setIsIntroduction={setIsIntroduction}
          resetState={resetState}
        />
      )}
      <TransitionGroup>
        {isShowExploreModal && (
          <CSSTransition timeout={250} classNames="fade-item">
            <Modal handleCloseModal={handleCloseModal} />
          </CSSTransition>
        )}
      </TransitionGroup>
      <TransitionGroup>
        {page == PAGES.END_SESSION_PAGE && (
          <CSSTransition timeout={1000} classNames="fade-item">
            <PostEngagement/>
          </CSSTransition>
        )}
      </TransitionGroup>
    </div>
  );
};

export default ReactUI;
