import React, { useEffect, useState } from 'react';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVolumeUp, faEye, faLightbulb, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import './index.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
  reqSetIsShowStartSessionModal,
  reqSetIsTransparent,
  reqSetPage,
} from '../../reduxs/home/action';
import preferenceApi from '../../apis/api/preference';
import customerApi from '../../apis/api/customer';
import socket, { emitUIActionEvent } from '../../helper/socket';
import { toast } from 'react-toastify';
import { useHistory, useLocation } from 'react-router';
import _ from 'lodash';
import { ACTION_NAME, PAGES, WEBSOCKET_CHANNEL, PAGENAMES } from '../../constants/options';
import config from '../../config';

library.add(faVolumeUp, faEye, faLightbulb, faEyeSlash);

const BottomNavigation = (props) => {
  const { isHideEye, resetState } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const agent = useSelector((state) => state.user.data);
  const isTransparent = useSelector((state) => state.home.isTransparent);
  const selectedUnit = useSelector((state) => state.unitExplore.selectedUnit);
  const activeAmenity = useSelector((state) => state.amenities.activeAmenity);
  const isPresentation = useSelector((state) => state.home.isPresentation);
  const authUser = useSelector((state) => state.user.data);
  const activePanoName = useSelector((state) => state.home.activePanoName);
  // const isShowImmerse = useSelector((state) => state.home.isShowImmerse);

  const [isLightOn, setLightOn] = useState(true);
  const [isAudioOn, setAudioOn] = useState(true);

  const customerId = useQuery().get('customer');
  const [preference, setPreference] = useState();
  const [customer, setCustomer] = useState();

  const page = useSelector((state) => state.home.page);

  useEffect(() => {
    if (isPresentation) {
      socket.on(WEBSOCKET_CHANNEL.SHARE_UI_ACTION, ({ content }) => {
        if (content.action === ACTION_NAME.CLICK_EYE_ICON) {
          handleClickEyeIcon(content.data.isTransparent);
        }
      });
    }
  }, [isPresentation]);

  useEffect(() => {
    if (customerId) {
      getCustomerPreference();
      getCustomerDetail();
    }
  }, [customerId, selectedUnit]);

  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  const getCustomerPreference = async () => {
    try {
      const res = await preferenceApi.getCustomerPreference({
        customer: customerId,
      });
      setPreference(res.data);
    } catch (error) {}
  };

  const getCustomerDetail = async () => {
    try {
      const res = await customerApi.getCustomerDetail(customerId);
      setCustomer(res.data);
    } catch (error) {
      toast.error(error?.message);
    }
  };

  const getUnitIds = () => {
    const units = preference?.units || [];

    return units.map((item) => item.id);
  };

  const getAmenityIds = () => {
    const amenities = preference?.amenities || [];

    return amenities.map((item) => item.id);
  };

  const handleSavePreference = () => {
    if (!selectedUnit && !activeAmenity) {
      return toast.error('Please select an unit or amenity!');
    }
    let data = {};

    if (selectedUnit) {
      let unitIds = getUnitIds();

      if (unitIds.includes(selectedUnit.id)) {
        unitIds = unitIds.filter((item) => item !== selectedUnit.id);
        data.units = unitIds;
      } else {
        unitIds.push(selectedUnit.id);
        data.units = unitIds;
      }
    }

    if (activeAmenity) {
      let amenityIds = getAmenityIds();

      if (amenityIds.includes(activeAmenity.id)) {
        amenityIds = amenityIds.filter((item) => item !== activeAmenity.id);
        data.amenities = amenityIds;
      } else {
        amenityIds.push(activeAmenity.id);
        data.amenities = amenityIds;
      }
    }

    if (customerId && Object.keys(data).length) {
      data.customer = customerId;
      preferenceApi.savePreference(data).then(() => {
        toast.success('Saved Preference successfully!');
        emitSavePreferenceEvent(data);
        getCustomerPreference();
      });
    }
  };

  const handleUnsavePreference = () => {
    if (!selectedUnit && !activeAmenity) {
      return toast.error('Please select an unit or amenity!');
    }
    let data = {};

    if (selectedUnit) {
      let unitIds = getUnitIds();

      if (unitIds.includes(selectedUnit.id)) {
        unitIds = unitIds.filter((item) => item !== selectedUnit.id);
        data.units = unitIds;
      } else {
        unitIds.push(selectedUnit.id);
        data.units = unitIds;
      }
    }

    if (activeAmenity) {
      let amenityIds = getAmenityIds();

      if (amenityIds.includes(activeAmenity.id)) {
        amenityIds = amenityIds.filter((item) => item !== activeAmenity.id);
        data.amenities = amenityIds;
      } else {
        amenityIds.push(activeAmenity.id);
        data.amenities = amenityIds;
      }
    }

    if (customerId && Object.keys(data).length) {
      data.customer = customerId;
      preferenceApi.savePreference(data).then(() => {
        toast.success('Unsaved Preference successfully!');
        emitSavePreferenceEvent(data);
        getCustomerPreference();
      });
    }
  };

  const renderFavoriteBtn = () => {
    // if (!customerId || (!selectedUnit && !activeAmenity)) return;

    const unitIds = getUnitIds();
    const amenityIds = getAmenityIds();

    if (unitIds.includes(selectedUnit?.id) || amenityIds.includes(activeAmenity?.id)) {
      return (
        <span className="btn-item" onClick={() => handleUnsavePreference()}>
          <svg
            width="30"
            height="29"
            viewBox="0 0 30 29"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.94007 28.6091C6.51873 28.6056 6.10927 28.4691 5.77009 28.2191C5.47194 28.0003 5.24027 27.7033 5.10081 27.3608C4.96135 27.0183 4.91955 26.6438 4.98008 26.2791L6.33009 18.399L0.610086 12.8191C0.351271 12.5636 0.167021 12.2424 0.0771578 11.89C-0.0127049 11.5376 -0.00478805 11.1673 0.100077 10.8191C0.21679 10.4669 0.429211 10.154 0.713541 9.91559C0.997871 9.67718 1.34291 9.52258 1.71009 9.46906L9.6201 8.31909L13.1501 1.14905C13.3157 0.815835 13.5709 0.535447 13.8871 0.339355C14.2033 0.143264 14.568 0.0392599 14.9401 0.0390625C15.3122 0.0392599 15.6768 0.143264 15.9931 0.339355C16.3093 0.535447 16.5645 0.815835 16.7301 1.14905L20.2601 8.31909L28.1701 9.46906C28.5373 9.52258 28.8823 9.67718 29.1666 9.91559C29.451 10.154 29.6634 10.4669 29.7801 10.8191C29.8849 11.1673 29.8929 11.5376 29.803 11.89C29.7132 12.2424 29.5289 12.5636 29.2701 12.8191L23.5501 18.399L24.9001 26.2791C24.9606 26.6438 24.9188 27.0183 24.7794 27.3608C24.6399 27.7033 24.4082 28.0003 24.1101 28.2191C23.8096 28.4384 23.4538 28.5696 23.0829 28.5979C22.7119 28.6262 22.3404 28.5504 22.0101 28.3791L14.9401 24.6591L7.8701 28.3791C7.58209 28.5267 7.2637 28.6054 6.94007 28.6091Z"
              fill="white"
            />
          </svg>
        </span>
      );
    }

    return (
      <span className="btn-item" onClick={() => handleSavePreference()}>
        <svg
          width="30"
          height="29"
          viewBox="0 0 30 29"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6.94007 28.6091C6.51873 28.6056 6.10927 28.4691 5.77009 28.2191C5.47194 28.0003 5.24027 27.7033 5.10081 27.3608C4.96135 27.0183 4.91955 26.6438 4.98008 26.2791L6.33009 18.399L0.610086 12.8191C0.351271 12.5636 0.167021 12.2424 0.0771578 11.89C-0.0127049 11.5376 -0.00478805 11.1673 0.100077 10.8191C0.21679 10.4669 0.429211 10.154 0.713541 9.91559C0.997871 9.67718 1.34291 9.52258 1.71009 9.46906L9.6201 8.31909L13.1501 1.14905C13.3157 0.815835 13.5709 0.535447 13.8871 0.339355C14.2033 0.143264 14.568 0.0392599 14.9401 0.0390625C15.3122 0.0392599 15.6768 0.143264 15.9931 0.339355C16.3093 0.535447 16.5645 0.815835 16.7301 1.14905L20.2601 8.31909L28.1701 9.46906C28.5373 9.52258 28.8823 9.67718 29.1666 9.91559C29.451 10.154 29.6634 10.4669 29.7801 10.8191C29.8849 11.1673 29.8929 11.5376 29.803 11.89C29.7132 12.2424 29.5289 12.5636 29.2701 12.8191L23.5501 18.399L24.9001 26.2791C24.9606 26.6438 24.9188 27.0183 24.7794 27.3608C24.6399 27.7033 24.4082 28.0003 24.1101 28.2191C23.8096 28.4384 23.4538 28.5696 23.0829 28.5979C22.7119 28.6262 22.3404 28.5504 22.0101 28.3791L14.9401 24.6591L7.8701 28.3791C7.58209 28.5267 7.2637 28.6054 6.94007 28.6091V28.6091ZM14.9401 1.98907L11.4201 9.16907C11.2766 9.45834 11.0652 9.70856 10.804 9.89838C10.5428 10.0882 10.2395 10.212 9.92008 10.2591L2.01008 11.4091L7.74009 16.9691C7.97133 17.1959 8.14401 17.4755 8.2433 17.7838C8.34258 18.0921 8.36552 18.4199 8.3101 18.7391L6.96009 26.6091L14.0201 22.889C14.3057 22.7401 14.623 22.6623 14.9451 22.6623C15.2672 22.6623 15.5845 22.7401 15.8701 22.889L22.9501 26.6191L21.5901 18.7491C21.5347 18.43 21.5576 18.1021 21.6569 17.7938C21.7562 17.4855 21.9288 17.2059 22.1601 16.9791L27.8901 11.399L19.9801 10.269C19.6606 10.2219 19.3574 10.0982 19.0961 9.90839C18.8349 9.71857 18.6235 9.46835 18.4801 9.17908L14.9401 2.00909V1.98907Z"
            fill="white"
          />
        </svg>
      </span>
    );
  };

  const emitSavePreferenceEvent = (data) => {
    socket.emit('private_message', {
      content: data,
      to: customerId,
      from: agent?.id,
    });
  };

  const onStartNewCustomerSession = () => {
    dispatch(reqSetIsShowStartSessionModal(true));
  };

  const onEndCustomerSession = () => {
    resetState();
    dispatch(reqSetPage(PAGES.END_SESSION_PAGE));
  };

  const renderCustomerName = () => {
    if (!customer) return;

    return `${customer?.firstname} ${customer?.surname}`;
  };

  const handleClickEyeIcon = (isTransparent) => {
    if (!isPresentation) {
      emitUIActionEvent(authUser, ACTION_NAME.CLICK_EYE_ICON, {
        isTransparent: isTransparent,
      });
    }
    dispatch(reqSetIsTransparent(!isTransparent));
  };

  const renderEyeIcon = () => {
    if (isHideEye) return;

    return (
      <li className={getInvisibleClass()}>
        <span
          className="btn-item"
          onClick={() => !isPresentation && handleClickEyeIcon(isTransparent)}
        >
          {isTransparent ? (
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2 16C2 16 6.9 21.6 16 21.6C25.1 21.6 30 16 30 16"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M4.8 18.3027L2 21.5997"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M30 21.6014L27.2056 18.3086"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M11.6795 21.1523L10.3999 25.1003"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M20.2881 21.1641L21.5999 25.1009"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          ) : (
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M16.0007 6C9.33398 6 3.64065 10.1467 1.33398 16C3.64065 21.8533 9.33398 26 16.0007 26C22.6673 26 28.3607 21.8533 30.6673 16C28.3607 10.1467 22.6673 6 16.0007 6ZM16.0007 22.6667C12.3207 22.6667 9.33398 19.68 9.33398 16C9.33398 12.32 12.3207 9.33333 16.0007 9.33333C19.6807 9.33333 22.6673 12.32 22.6673 16C22.6673 19.68 19.6807 22.6667 16.0007 22.6667ZM16.0007 12C13.7873 12 12.0007 13.7867 12.0007 16C12.0007 18.2133 13.7873 20 16.0007 20C18.214 20 20.0007 18.2133 20.0007 16C20.0007 13.7867 18.214 12 16.0007 12Z"
                fill="white"
              />
            </svg>
          )}
        </span>
      </li>
    );
  };

  const getInvisibleClass = () => {
    return isPresentation ? 'invisible' : '';
  };

  return (
    <div className={`wrap-bottom-navigation`}>
      <nav className="nav-menu">
        <ul style={selectedUnit?.name || PAGENAMES[page] ? { paddingTop: '94.5px' } : null}>
          {renderEyeIcon()}
          <li className={getInvisibleClass()}>
            <span className="btn-item" onClick={() => !isPresentation && setLightOn(!isLightOn)}>
              {isLightOn ? (
                <svg
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M26 27.9998H25.9998L19.999 27.999C19.4688 27.9983 18.9605 27.7874 18.5856 27.4124C18.2108 27.0375 17.9999 26.5292 17.9993 25.999V19.9989H13.9993V25.9989C13.9986 26.5291 13.7877 27.0374 13.4129 27.4123C13.038 27.7873 12.5297 27.9982 11.9995 27.9989L6.00025 27.9999C5.73759 27.9999 5.47749 27.9482 5.23481 27.8477C4.99213 27.7472 4.77162 27.5999 4.58588 27.4142C4.40013 27.2285 4.25279 27.008 4.15227 26.7653C4.05174 26.5226 4 26.2626 4 25.9999V14.4424C4.00022 14.1637 4.05855 13.8882 4.17126 13.6334C4.28398 13.3785 4.44861 13.15 4.65462 12.9624L14.6538 3.87077C15.021 3.53434 15.501 3.3477 15.9991 3.34766C16.4972 3.34761 16.9773 3.53416 17.3446 3.87052L27.3455 12.9625C27.5515 13.1502 27.716 13.3787 27.8287 13.6335C27.9414 13.8883 27.9997 14.1638 28 14.4424V25.9999C28 26.5303 27.7893 27.039 27.4142 27.4141C27.0391 27.7892 26.5304 27.9999 26 27.9999V27.9998ZM13.9993 17.9989H17.9993C18.5295 17.9995 19.0379 18.2104 19.4128 18.5853C19.7877 18.9603 19.9987 19.4686 19.9993 19.9989V26.0001L26 25.9999V14.4424L15.9993 5.35052L6 14.4423V25.9998L11.9993 25.999V19.9989C11.9998 19.4686 12.2108 18.9603 12.5857 18.5853C12.9606 18.2104 13.469 17.9995 13.9993 17.9989Z"
                    fill="white"
                  />
                </svg>
              ) : (
                <svg
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M26 27.9998L19.999 27.999C19.4688 27.9983 18.9605 27.7874 18.5856 27.4124C18.2108 27.0375 17.9999 26.5292 17.9993 25.999V19.9989H13.9993V25.9989C13.9986 26.5291 13.7877 27.0374 13.4129 27.4123C13.038 27.7873 12.5297 27.9982 11.9995 27.9989L6.00025 27.9999C5.73759 27.9999 5.47749 27.9482 5.23481 27.8477C4.99213 27.7472 4.77162 27.5999 4.58588 27.4142C4.40013 27.2285 4.25279 27.008 4.15227 26.7653C4.05174 26.5226 4 26.2626 4 25.9999V14.4424C4.00022 14.1637 4.05855 13.8882 4.17126 13.6334C4.28398 13.3785 4.44861 13.15 4.65462 12.9624L14.6538 3.87077C15.021 3.53434 15.501 3.3477 15.9991 3.34766C16.4972 3.34761 16.9773 3.53416 17.3446 3.87052L27.3455 12.9625C27.5515 13.1502 27.716 13.3787 27.8287 13.6335C27.9414 13.8883 27.9997 14.1638 28 14.4424V25.9999C28 26.5303 27.7893 27.039 27.4142 27.4141C27.0391 27.7892 26.5304 27.9998 26 27.9998Z"
                    fill="white"
                  />
                </svg>
              )}
            </span>
          </li>
          <li className={getInvisibleClass()}>
            <span className="btn-item" onClick={() => !isPresentation && setAudioOn(!isAudioOn)}>
              {isAudioOn ? (
                <svg
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4 12V20H9.33333L16 26.6666V5.33331L9.33333 12H4ZM22 16C22 13.64 20.64 11.6133 18.6667 10.6266V21.36C20.64 20.3866 22 18.36 22 16ZM18.6667 4.30664V7.05331C22.52 8.19997 25.3333 11.7733 25.3333 16C25.3333 20.2266 22.52 23.8 18.6667 24.9466V27.6933C24.0133 26.48 28 21.7066 28 16C28 10.2933 24.0133 5.51997 18.6667 4.30664Z"
                    fill="white"
                  />
                </svg>
              ) : (
                <svg
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4 12.0007V20.0007H9.33333L16 26.6673V5.33398L9.33333 12.0007H4Z"
                    fill="white"
                  />
                </svg>
              )}
            </span>
          </li>
          {customerId ? (
            <li className={`${getInvisibleClass()} w-2x`}>
              <a
                className="text-white"
                hidden={page == PAGES.END_SESSION_PAGE}
                href="#"
                onClick={() => !isPresentation && onEndCustomerSession()}
              >
                <u>END SESSION</u>
              </a>
            </li>
          ) : (
            <li className={`w-3x ${getInvisibleClass()}`}>
              <a
                className="text-white"
                href="#"
                onClick={() => !isPresentation && onStartNewCustomerSession()}
              >
                <u>START SESSION</u>
              </a>
            </li>
          )}

          <li className={`w-auto`}>
            <span
              style={selectedUnit?.name && { paddingRight: '0px' }}
              className="f-title text-ellipsis-1line"
            >
              {/* {selectedUnit?.name ||
                PAGENAMES[page] ||
                (isShowImmerse ? activePanoName : '') ||
                ' '} */}{' '}
            </span>
          </li>

          <li className={`w-4x ${getInvisibleClass()}`}>
            <span className="text-ellipsis-1line text-end pe-3">{renderCustomerName()}</span>
          </li>

          {customerId && (selectedUnit || activeAmenity) && (
            <li className={getInvisibleClass()}>{renderFavoriteBtn()}</li>
          )}
          {!customerId && (
            <li className={getInvisibleClass()}>
              <a href="/cms">
                <span className="btn-item">
                  <svg
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10 8.66602C10 11.974 12.692 14.666 16 14.666C19.308 14.666 22 11.974 22 8.66602C22 5.35802 19.308 2.66602 16 2.66602C12.692 2.66602 10 5.35802 10 8.66602ZM26.6667 27.9994H28V26.666C28 21.5207 23.812 17.3327 18.6667 17.3327H13.3333C8.18667 17.3327 4 21.5207 4 26.666V27.9994H26.6667Z"
                      fill="white"
                    />
                  </svg>
                </span>
              </a>
            </li>
          )}
        </ul>
      </nav>
    </div>
  );
};

export default BottomNavigation;
