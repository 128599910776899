import masterData from './master-data';

export const AVAILABILITY_STATUS_OPTION = [
  {
    value: masterData.AVAILABILITY_STATUS_ENUM.AVAILABLE_NOW,
    title: 'Available now',
  },
  {
    value: masterData.AVAILABILITY_STATUS_ENUM.IN_CONTRACT,
    title: 'In Contract',
  },
  {
    value: masterData.AVAILABILITY_STATUS_ENUM.SOLD,
    title: 'Sold',
  },
  {
    value: masterData.AVAILABILITY_STATUS_ENUM.RELEASING_SOON,
    title: 'Releasing soon',
  }
];

export const BEDROOM_OPTION = [
  {
    title: '2',
    value: 2,
  },
  {
    title: '3',
    value: 3,
  },
  {
    title: '4',
    value: 4,
  },
  {
    title: '5',
    value: 5,
  },
  {
    title: '6',
    value: 6,
  },
];

export const PARKING_STALLS = [
  {
    title: '1',
    value: 1,
  },
  {
    title: '2',
    value: 2,
  },
  {
    title: '3',
    value: 3,
  },
];

export const AREA_OPTION = [
  {
    id: 1,
    min: 2000,
    max: 3000,
    title: '2000 - 3000 sqft',
  },
  {
    id: 2,
    min: 3000,
    max: 4000,
    title: '3000 - 4000 sqft',
  },
  {
    id: 3,
    min: 4000,
    title: '4000 + sqft',
  },
];

export const FLOORPLAN_NAME_OPTION = [
  {
    title: 'Eucalyptus',
    value: 'Eucalyptus',
  },
  {
    title: "Laurel",
    value: "Laurel"
  },
  {
    title: "Madrone A",
    value: "Madrone A"
  },
  {
    title: "Madrone B",
    value: "Madrone B",
  },
  {
    title: "Madrone C",
    value: "Madrone C",
  },
  {
    title: "Manzanita",
    value: "Manzanita",
  },
  {
    title: "Maple",
    value: "Maple",
  },
  {
    title: "Oak",
    value: "Oak",
  },
  {
    title: "Sycamore A",
    value: "Sycamore A",
  },
  {
    title: "Sycamore B",
    value: "Sycamore B",
  },
  {
    title: "Sycamore C",
    value: "Sycamore C",
  },
]

export const FLOORPLAN_CODE_OPTION = [
  {
    title: '1A',
    value: '1A',
  },
  {
    title: "1AR",
    value: "1AR"
  },
  {
    title: "1X",
    value: "1X"
  },
  {
    title: "2A",
    value: "2A",
  },
  {
    title: "2B",
    value: "2B",
  },
  {
    title: "2C",
    value: "2C",
  },
  {
    title: "3A",
    value: "3A",
  },
  {
    title: "3B",
    value: "3B",
  },
  {
    title: "3C",
    value: "3C",
  },
  {
    title: "4A",
    value: "4A",
  },
  {
    title: "4X",
    value: "4X",
  },
];

export const ENCLAVE_OPTION = [
  {
    title: 1,
    value: 1,
  },
  {
    title: 2,
    value: 2,
  },
  {
    title: 3,
    value: 3,
  },
  {
    title: 4,
    value: 4,
  },
  {
    title: 5,
    value: 5,
  },
  {
    title: 6,
    value: 6,
  },
  {
    title: 7,
    value: 7,
  },
  {
    title: 8,
    value: 8,
  },
  {
    title: 9,
    value: 9,
  },
];

export const PRICE_OPTION = [
  {
    id: 1,
    title: 'Starting 3M',
    min: 3000000,
    max: 4000000,
  },
  {
    id: 2,
    title: 'Starting 4M',
    min: 4000000,
    max: 5000000,
  },
  {
    id: 3,
    title: 'Starting 5M',
    min: 5000000,
  }
];

export const SQUARE_FOOTAGE_OPTION = [
  {
    id: 1,
    title: 'Starting 2000 sq ft',
    min: 2000,
    max: 3000,
  },
  {
    id: 2,
    title: 'Starting 3000 sq ft',
    min: 3000,
    max: 4000,
  },
  {
    id: 3,
    title: 'Starting 4000 sq ft',
    min: 4000,
    max: 5000,
  },
  {
    id: 4,
    title: 'Starting 5000 sq ft',
    min: 5000,
  },
];

export const PAGES = {
  NONE: 'NONE',
  INTRODUCE_PAGE: 'INTRODUCE_PAGE',
  AMENITY_PAGE: 'AMENITY_PAGE',
  UNIT_EXPLORER_PAGE: 'UNIT_EXPLORER_PAGE',
  GALLERY_PAGE: 'GALLERY_PAGE',
  IMMERSE_PAGE: 'IMMERSE_PAGE',
  TEAM_PAGE: 'TEAM_PAGE',
  LOCATION_PAGE: 'LOCATION_PAGE',
  ONBOARD_PAGE: 'ONBOARD_PAGE',
  EXCUTIVE_SUMMARY_PAGE: 'EXCUTIVE_SUMMARY_PAGE',
  SEARCH_PAGE: 'SEARCH_PAGE',
  CLIENT_ENGAGEMENT: 'CLIENT_ENGAGEMENT_PAGE',
  PREVIEW_PAGE: 'PREVIEW_PAGE',
  END_SESSION_PAGE: 'END_SESSION_PAGE',
  ANIMATE_PAGE: 'ANIMATE_PAGE'
};

export const PAGENAMES = {
  GALLERY_PAGE: 'Gallery',
  TEAM_PAGE: 'The Team',
  LOCATION_PAGE: 'Location',
  SEARCH_PAGE: 'Search'
};

export const LAYERS = {
  NONE: 0,
  HOTPOT: 1,
  UNITS: 2,
  DISABLE: 3,
  INVISIBLE_BUT_ENABLE: 4,
  VISIBLE_BUT_DISABLE: 5,
};

export const OBJECT_TYPES = {
  UNIT: 'unit',
  AREA: 'area',
  AMENITY: 'amenity',
};

export const HOTSPOT_TYPES = {
  GALLERY: 'gallery',
  PANO_TOUR: '360_pano_tour',
  URL: 'url',
  VIDEO: 'video',
  EXCUTIVE_SUMMARY: 'excutive_summary',
  AMENITY: 'amenity',
  UNIT_EXPLORE: 'unit_explore',
  MODAL: 'modal',
  AMENITY_ITEM: 'amenity_item',
};

export const ACTION_NAME = {
  CLICK_UNIT_EXPLORE_MENU: 'CLICK_UNIT_EXPLORE_MENU',
  CLICK_LOCATION_MENU: 'CLICK_LOCATION_MENU',
  CLICK_CUBE_MENU: 'CLICK_CUBE_MENU',
  CLICK_GALLERY_MENU: 'CLICK_GALLERY_MENU',
  CLICK_TEAM_MENU: 'CLICK_TEAM_MENU',
  CLICK_IMMERSER_MENU: 'CLICK_IMMERSER_MENU',
  CLICK_SEARCH_MENU: 'CLICK_SEARCH_MENU',
  CLICK_START_DISCOVER: 'CLICK_START_DISCOVER',
  CLICK_UNIT_FILTER: 'CLICK_UNIT_FILTER',
  CLEAR_ALL_FILTERS: 'CLEAR_ALL_FILTERS',
  CLICK_UNIT: 'CLICK_UNIT',
  CLICK_HOTSPOT: 'CLICK_HOTSPOT',
  CLICK_AMENITY_ITEM: 'CLICK_AMENITY_ITEM',
  CLICK_CLOSE_MODAL: 'CLICK_CLOSE_MODAL',
  CLICK_MODAL_BUTTON: 'CLICK_MODAL_BUTTON',
  CHANGE_MODAL_CONTENT: 'CHANGE_MODAL_CONTENT',
  SHOW_UNIT_GALLERY: 'SHOW_UNIT_GALLERY',
  CLOSE_UNIT_GALLERY: 'CLOSE_UNIT_GALLERY',
  SHOW_UNIT_FLOORPLAN: 'SHOW_UNIT_FLOORPLAN',
  CLOSE_UNIT_FLOORPLAN: 'CLOSE_UNIT_FLOORPLAN',
  CLICK_EYE_ICON: 'CLICK_EYE_ICON',
  CLICK_NEXT_GALLERY: 'CLICK_NEXT_GALLERY',
  CLICK_PREV_GALLERY: 'CLICK_PREV_GALLERY',
  CHANGE_SLIDE_GALLERY: 'CHANGE_SLIDE_GALLERY',
  CLICK_FLOORPLAN_SLIDE_BTN: 'CLICK_FLOORPLAN_SLIDE_BTN',
  ZOOM_IN_FLOORPLAN: 'ZOOM_IN_FLOORPLAN',
  ZOOM_OUT_FLOORPLAN: 'ZOOM_OUT_FLOORPLAN',
  CLICK_SEARCH_UNIT: 'CLICK_SEARCH_UNIT',
  CLICK_SEARCH_AMENITY: 'CLICK_SEARCH_AMENITY',
  CLICK_SEARCH_POI: 'CLICK_SEARCH_POI',
  CHANGE_POI_MODAL: 'CHANGE_POI_MODAL',
  CLICK_POI_GROUP: 'CLICK_POI_GROUP',
  CLOSE_POI_MODAL: 'CLOSE_POI_MODAL',
  CHANGE_POI_GROUP: 'CHANGE_POI_GROUP',
  CHANGE_ACTIVE_POI: 'CHANGE_ACTIVE_POI',
  CHANGE_ACTIVE_TYPE: 'CHANGE_ACTIVE_TYPE',
  WINDOW_SIZE: 'WINDOW_SIZE',
  CHANGE_PANORAMA_CAMERA: 'CHANGE_PANORAMA_CAMERA',
  CLOSE_PANO_VIEWER: 'CLOSE_PANO_VIEWER',
  CLICK_END_SESSION: 'CLICK_END_SESSION',
  SHOW_INTRO_SLIDE: 'SHOW_INTRO_SLIDE',
  CHANGE_INTRO_SLIDE: 'CHANGE_INTRO_SLIDE',
  EXPLORE_SHOW_FILTER: 'EXPLORE_SHOW_FILTER'
}

export const WEBSOCKET_CHANNEL = {
  SHARE_UI_ACTION: 'share_screen_ui_action',
  SHARE_CAMERA_ACTION: 'share_screen',
  SHARE_3D_BUILDING_ACTION: 'share_3d_building_action',
  SHARE_PANORAMA_ACTION: 'share_panorama_action',
}

export default {
  AVAILABILITY_STATUS_OPTION,
  PARKING_STALLS,
  PAGES,
  LAYERS,
  OBJECT_TYPES,
  HOTSPOT_TYPES,
  ACTION_NAME,
  WEBSOCKET_CHANNEL,
  FLOORPLAN_NAME_OPTION,
  FLOORPLAN_CODE_OPTION,
  ENCLAVE_OPTION,
  PRICE_OPTION,
};
