import React, { useEffect, useState } from 'react';
import Divider from './modules/divider';
import customerApi from '../../apis/api/customer';
import preferenceApi from '../../apis/api/preference';
import _ from 'lodash';
import FirstUnitDetailStyle from './modules/FirstUnitDetailStyle';
import SecondUnitDetailStyle from './modules/SecondUnitDetailStyle';
import ThirdUnitDetailStyle from './modules/ThirdUnitDetailStyle';

import './style.scss';
import { useParams, useLocation, useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { ACTION_NAME } from '../../constants/options';
import { emitUIActionEvent } from '../../helper/socket';

const PostEngagement = (props) => {
  const history = useHistory();
  const { content } = props;
  const { customerId } = useParams();
  const [customer, setCustomer] = useState();
  const [preference, setPreference] = useState();
  const locationCustomerId = useQuery().get('customer');
  const isPresentation = useSelector((state) => state.home.isPresentation);
  const authUser = useSelector((state) => state.user.data);
  useEffect(() => {
    if (customerId || locationCustomerId) {
      getCustomerDetail();
      getCustomerPreference();
    }
  }, [customerId, locationCustomerId]);

  useEffect(() => {
    if (content) {
      getCustomerPreference();
    }
  }, [content]);

  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  const getCustomerDetail = async () => {
    const res = await customerApi.getCustomerDetail(customerId ? customerId : locationCustomerId);
    setCustomer(res.data);
  };
  const getCustomerPreference = async () => {
    try {
      const res = await preferenceApi.getCustomerPreference({
        customer: customerId ? customerId : locationCustomerId,
      });
      setPreference(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  const renderUnitDetail = () => {
    const units = preference?.units || [];
    let style = 1;

    return units.map((unit, index) => {
      if (style === 1) {
        style = style < 3 ? style + 1 : 1;

        return (
          <div key={index}>
            {renderDivider(index)}
            <FirstUnitDetailStyle unit={unit} />
          </div>
        );
      }

      if (style === 2) {
        style = style < 3 ? style + 1 : 1;

        return (
          <div key={index}>
            {renderDivider(index)}
            <SecondUnitDetailStyle unit={unit} />
          </div>
        );
      }

      style = 1;

      return (
        <div key={index}>
          {renderDivider(index)}
          <ThirdUnitDetailStyle unit={unit} />
        </div>
      );
    });
  };

  const renderDivider = (index) => {
    if (index === 0) return;

    if (index % 2 === 0) {
      return <Divider imgName="/images/post-engagement-divider-light.png" />;
    }

    return <Divider imgName="/images/post-engagement-divider-dark.png" />;
  };
  
  const endSession = () => {
    if (!isPresentation) {
      emitUIActionEvent(authUser, ACTION_NAME.CLICK_END_SESSION);
    }
    history.push('/holding');
  };

  return (
    <div className="wrap-post-engagement">
      <div className="bg-white">
        <header className={`bg-white ${preference ? '' : 'vh-100'}`}>
          <div className="container">
            <figure className="mb-5">
              <img src="/images/post-engagement-logo.png" alt="" width="34" height="34" />
            </figure>
            <div className="row">
              <div className="col">
                <p className="h1 f-title">
                  Hi {customer?.firstname} {customer?.surname},
                </p>
                <p className="mb-2">
                  Here are the items you expressed an interest in during your session with us at
                  Stanly Ranch....
                </p>
              </div>
              <div className="col-auto">
                <a
                  className="btn btn-black"
                  style={{ textDecoration: 'none' }}
                  href="#"
                  onClick={endSession}
                >
                  End Session
                </a>
              </div>
            </div>
          </div>
        </header>
        <main>{renderUnitDetail()}</main>
        <footer className="py-4 mt-5 bg-dark">
          <div className="container">
            <ul className="list-group list-group-horizontal">
              <li className="list-group-item ps-0 pe-2">
                <a href="#" className="text-white" target="_blank">
                  <img src="/images/post-engagement-footer-home-icon.png" width="16" alt="" />
                </a>
              </li>
              <li className="list-group-item">
                <a href="#" className="text-white" target="_blank">
                  DISCLAIMER
                </a>
              </li>
              <li className="list-group-item">
                <a href="#" className="text-white" target="_blank">
                  PRIVACY POLICY
                </a>
              </li>
            </ul>
            <p className="fs-xs">
              This website is dedicated to Stanly Ranch. Neither Auberge Resorts LLC nor any of its
              affiliates or related persons (the <q>Auberge Group</q>) makes any representation,
              warranty or guarantee, expressed or implied, in respect of any statement or
              information made or contained in this website. Neither the Auberge Group nor any of
              its directors, officers, employees or agents has or will have any responsibility or
              liability arising out of, or related to. this website or the transactions contemplated
              by this website, including any liability or responsibility for any statement or
              information made or contained in or the use of this website.The information and
              materials on this website are for informational purposes only and are non-binding and
              do not constitute an offer or acceptance of an offer. No binding agreement will arise
              until both the seller and purchaser execute and deliver a separate definitive
              agreement. Please see the Disclaimer and Privacy Policy for binding terms governing
              your use of this website. Represented by Compass Development Marketing Group DRE
              #01527235
            </p>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default PostEngagement;
