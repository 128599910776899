import React from "react";
import './index.scss';
import Fancybox from "./fancy-box";
import galleryApi from '../../apis/api/gallery';
import { useState } from "react";
import { useEffect } from "react";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import config from "../../config";
import { reqSetIsShowFilms } from "../../reduxs/media/action";

const HomeGallery = () => {
  const [gallery, setGallery] = useState();
  const [media, setMedia] = useState([]);
  const activeGalleryId = useSelector((state) => state.home.activeGalleryId);
  const page = useSelector((state) => state.home.page);
  const [isShowFancyBox, setIsShowFancyBox] = useState(false);
  const isShowFilms = useSelector((state) => state.media.isShowFilms);
  
  const dispatch = useDispatch();

  const cardStyles = [];

  useEffect(() => {
    if(isShowFancyBox) {
      const fancyBoxRef = document.getElementsByClassName('fancybox__container')[0]?.Fancybox;
      fancyBoxRef.on('destroy', (e) => setIsShowFancyBox(false));
    }
  }, [isShowFancyBox])

  useEffect(async () => {
    if (activeGalleryId) {
      const res = await galleryApi.getGalleryDetail(activeGalleryId);
      setGallery(res.data);
    }
  }, [activeGalleryId]);

  useEffect(async () => {
    setMedia(gallery?.media ? gallery.media : []);
  }, [gallery]);

  const onNextImage = () => {
    const fancyBoxRef = document.getElementsByClassName('fancybox__container')[0]?.Fancybox;
    fancyBoxRef?.next();
  }

  const onPrevImage = () => {
    const fancyBoxRef = document.getElementsByClassName('fancybox__container')[0]?.Fancybox;
    fancyBoxRef?.prev();
  }

  const onCloseFancyBox = () => {
    const fancyBoxRef = document.getElementsByClassName('fancybox__container')[0]?.Fancybox;
    setIsShowFancyBox(false);
    fancyBoxRef?.close();
  }

  const onSelectGallery = () => {
    dispatch(reqSetIsShowFilms(false));
  }

  const onSelectMedia = () => {
    dispatch(reqSetIsShowFilms(true));
  }

  const getRandomIn = (max) => {
    return Math.floor(Math.random() * max);
  }

  const getCardClass = (index, style) => {
    if (style.cardTalls.includes(index)) return 'card-tall';

    if (style.cardWides.includes(index)) return 'card-wide';
    
    if (style.style1.includes(index)) return 'card-style1';
    if (style.style2.includes(index)) return 'card-style2';
    if (style.style3.includes(index)) return 'card-style3';
    if (style.style4.includes(index)) return 'card-style4';
    if (style.style5.includes(index)) return 'card-style5';
    if (style.style6.includes(index)) return 'card-style6';
    if (style.style7.includes(index)) return 'card-style7';
    if (style.style8.includes(index)) return 'card-style8';

    return;
  }

  const styles = [
    {
      cardWides: [],
      cardTalls: [],
      style1: [],
      style2: [1,2],
      style3: [],
      style4: [],
      style5: [],
      style6: [0,3],
      style7: [],
      style8: []
    },
    {
      cardWides: [],
      cardTalls: [],
      style1: [],
      style2: [],
      style3: [1,2,3],
      style4: [],
      style5: [],
      style6: [],
      style7: [0],
      style8: []
    },
    {
      cardWides: [],
      cardTalls: [],
      style1: [],
      style2: [],
      style3: [1,3],
      style4: [],
      style5: [0,2],
      style6: [],
      style7: [],
      style8: []
    },
    {
      cardWides: [],
      cardTalls: [],
      style1: [],
      style2: [1],
      style3: [3],
      style4: [],
      style5: [0],
      style6: [2],
      style7: [],
      style8: []
    },
    {
      cardWides: [],
      cardTalls: [],
      style1: [],
      style2: [0,3],
      style3: [],
      style4: [],
      style5: [],
      style6: [1,2],
      style7: [],
      style8: []
    },
    {
      cardWides: [],
      cardTalls: [],
      style1: [],
      style2: [],
      style3: [],
      style4: [0,1,2,3],
      style5: [],
      style6: [],
      style7: [],
      style8: []
    },
  ];

  const renderStyleOne = (items, index) => {
    const styleIndex = index % 6;
    const style = styles[styleIndex];

    return (
      (items || []).map((item, index) => {
        return (
          <img key={index} onClick={() => setIsShowFancyBox(true)} className={`card border-0 ${getCardClass(index, style)}`} src={`${config.SITE_URL}${item.path}`} data-fancybox="gallery" data-src={`${config.SITE_URL}${item.path}`} />
        )
      })
    )
  }

  return (
    <>
      <div className="wrap-home-gallery">
        <div className="home-gallery-header container">
          <div className="media-type-header">
            <div className="media-type-buttons">
              <button className={`${!isShowFilms ? 'media-type-button-active' : 'media-type-button' }`} onClick={onSelectGallery}>
                GALLERY
              </button>
              {/* <button className={`${!isShowFilms ? 'media-type-button' : 'media-type-button-active' }`} onClick={onSelectMedia}> */}
              {/*   FILMS */}
              {/* </button> */}
            </div>
          </div>
          {
            !isShowFilms ? <div className="wrap-image-list">
            <Fancybox setIsShowFancyBox={setIsShowFancyBox} options={{ infinite: true, mainClass: 'wrap-fancy-box', showNavArrows: false }}>
              <div className="image-mosaic">
                {
                  _.chunk((gallery?.media || []), 4).map((items, index) => {
                    return renderStyleOne(items, index);
                  })
                }
              </div>
            </Fancybox>
          </div> :
          <div className="wrap-image-list">
          <Fancybox setIsShowFancyBox={setIsShowFancyBox} options={{ infinite: true, mainClass: 'wrap-fancy-box', showNavArrows: false }}>
            <h2 className="headertitle __title">
              Vineyard homes Films
            </h2>
            <div className="image-mosaic">
              <img key={0} onClick={() => setIsShowFancyBox(true)} className={`card border-0 style4`} src={`${config.SITE_URL}/uploads/images/gallery-villas/gallery-villas-01.jpg`} data-fancybox="gallery" data-src={`${config.SITE_URL}/uploads/images/gallery-villas/gallery-villas-01.jpg`} />
              <img key={1} onClick={() => setIsShowFancyBox(true)} className={`card border-0 style4`} src={`${config.SITE_URL}/uploads/images/gallery-villas/gallery-villas-02.jpg`} data-fancybox="gallery" data-src={`${config.SITE_URL}/uploads/images/gallery-villas/gallery-villas-02.jpg`} />
            </div>
            <h2 className="headertitle __title">
              The Villas Films
            </h2>
            <div className="image-mosaic">
              <img key={0} onClick={() => setIsShowFancyBox(true)} className={`card border-0 style4`} src={`${config.SITE_URL}/uploads/images/gallery-vinyard-homes/gallery-vinyard-homes-01.jpg`} data-fancybox="gallery" data-src={`${config.SITE_URL}/uploads/images/gallery-vinyard-homes/gallery-vinyard-homes-01.jpg`} />
              <img key={1} onClick={() => setIsShowFancyBox(true)} className={`card border-0 style4`} src={`${config.SITE_URL}/uploads/images/gallery-vinyard-homes/gallery-vinyard-homes-02.jpg`} data-fancybox="gallery" data-src={`${config.SITE_URL}/uploads/images/gallery-vinyard-homes/gallery-vinyard-homes-02.jpg`} />
            </div>
            <h2 className="headertitle __title">
              Other Films
            </h2>
            <div className="image-mosaic">
              <img key={0} onClick={() => setIsShowFancyBox(true)} className={`card border-0 style4`} src={`${config.SITE_URL}/uploads/images/gallery-general/gallery-general-01.jpg`} data-fancybox="gallery" data-src={`${config.SITE_URL}/uploads/images/gallery-general/gallery-general-01.jpg`} />
              <img key={1} onClick={() => setIsShowFancyBox(true)} className={`card border-0 style4`} src={`${config.SITE_URL}/uploads/images/gallery-general/gallery-general-02.jpg`} data-fancybox="gallery" data-src={`${config.SITE_URL}/uploads/images/gallery-general/gallery-general-02.jpg`} />
            </div>
          </Fancybox>
        </div>
          }
        </div>

      </div>
      {
        isShowFancyBox && (
          <>
            <div className="wrap-btn-fancy">
              <div className="btn-prev cursor-pointer" onClick={onPrevImage}>
                <img className="img-fluid" src="./icons/left-arrow.svg" alt="" />
              </div>
              <div className="btn-next cursor-pointer" onClick={onNextImage}>
                <img className="img-fluid" src="./icons/right-arrow.svg" alt="" />
              </div>
            </div>
            <div className="btn-close-fancy cursor-pointer" onClick={onCloseFancyBox}>
              <img className="img-fluid" src="./icons/close.svg" alt="" />
            </div>
          </>
        )
      }
    </>
  );
}

export default HomeGallery;
